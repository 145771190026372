import React from 'react';
import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

const toKebabCase = (str) => {
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('-');
};

const Tags = ({ data }) => {
  const tags = data.allMarkdownRemark.group;

  return (
    <Layout title="Alle project categorieën">
      <PageHeader header>
        <h1>Alle project categorieën</h1>
      </PageHeader>

      <StyledLinks>
        {tags.map((tag) => (
          <li key={tag.fieldValue}>
            <Link to={`/tags/${toKebabCase(tag.fieldValue)}/`}>
              {tag.fieldValue} ({tag.totalCount})
            </Link>
          </li>
        ))}
      </StyledLinks>
    </Layout>
  );
};

export default Tags;

const StyledLinks = styled.ul`
  & a {
    color: inherit;
    font-size: var(--size-400);
  }
`;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
